import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { CfForm, uiColors, usdToNumber } from '@cryptofi/core-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseQueryResult } from '@tanstack/react-query';
import { camelize } from 'humps';
import { size } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { KycFormField, KycFormFieldCollection, KycGroup, Product } from '~/customTypes';
import { useGetFiInfo, useGetUser, usePostKyc, usePostTelemetryEvent } from '~/hooks';
import { getErrorDetail } from '~/utils';

import KycLoading from './KycLoading';
import { KycFormValues, kycSchema } from './kycSchema';
import KycSection from './KycSection';

type GroupedFields = Record<KycGroup, { heading: string; fields: KycFormField[] }>;

const KycInfoView = ({
  kycFields,
  selectedProducts,
  isOnboarded,
}: {
  kycFields: UseQueryResult<KycFormFieldCollection, Error>;
  selectedProducts: Product[];
  isOnboarded: boolean;
}) => {
  const user = useGetUser();
  const fiInfo = useGetFiInfo();
  const postkyc = usePostKyc({ selectedProducts });
  const { trackEvent } = usePostTelemetryEvent();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(kycSchema({ neededFields: kycFields.data?.need || [] })),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = (formValues: KycFormValues) => {
    // remove USD formatting before submitting to API
    formValues.netWorthInteger = usdToNumber({ usd: String(formValues.netWorthInteger) });
    formValues.incomePerYearInteger = usdToNumber({ usd: String(formValues.incomePerYearInteger) });

    postkyc.mutate(formValues);
    trackEvent(TelemetryClientSideEventsEnum.KYCModalClickedConfirmAndSubmitButtonClient);
  };

  useEffect(() => {
    trackEvent(TelemetryClientSideEventsEnum.KYCModalViewedKYCFormClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [groupedFields, setGroupedFields] = useState<GroupedFields>();

  useEffect(() => {
    const initGroupedFields: GroupedFields = {
      personalInformation: {
        heading: 'Personal info',
        fields: [],
      },
      address: {
        heading: 'Address',
        fields: [],
      },
      employment: {
        heading: 'Income and employment',
        fields: [],
      },
      investmentExperience: {
        heading: 'Investment experience',
        fields: [],
      },
      trustedContact: {
        heading: 'Trusted contact',
        fields: [],
      },
      contact: {
        heading: 'Contact info',
        fields: [],
      },
      other: {
        heading: 'Other',
        fields: [],
      },
    };

    const groupKycFields = (ungroupedFields: KycFormField[]): GroupedFields => {
      const cloned = structuredClone(initGroupedFields);

      ungroupedFields.forEach((field) => {
        switch (camelize(field.name)) {
          case 'firstName':
          case 'middleName':
          case 'lastName':
          case 'dateOfBirth':
          case 'ssn':
            cloned.personalInformation.fields.push(field);
            break;

          case 'address1':
          case 'address2':
          case 'city':
          case 'state':
          case 'postal':
          case 'country':
            cloned.address.fields.push(field);
            break;

          case 'incomePerYearInteger':
          case 'netWorthInteger':
          case 'employer':
          case 'subjectToBackupWithholding':
            cloned.employment.fields.push(field);
            break;

          case 'investmentObjective':
          case 'yearsStocksInteger':
          case 'levelStocks':
          case 'executiveOrShareholder':
          case 'workForExchangeOrBrokerage':
          case 'riskTolerance':
            cloned.investmentExperience.fields.push(field);
            break;

          case 'phone':
          case 'email':
            cloned.contact.fields.push(field);
            break;

          case 'trustedContactf':
          case 'trustedContactl':
          case 'trustedEmail':
          case 'trustedPhone':
            cloned.trustedContact.fields.push(field);
            break;

          default:
            cloned.other.fields.push(field);
            break;
        }
      });

      return cloned;
    };

    if (kycFields.data?.need) {
      setGroupedFields(groupKycFields(kycFields.data?.need || []));
    }
  }, [kycFields.data?.need, setGroupedFields]);

  if (kycFields.isError || user?.isError) {
    return (
      <Flex flexDir="column" gap="6" alignItems="center" mt="12">
        <Heading as="h2" size="sm">
          Something went wrong
        </Heading>

        <Text>There was an error while processing your information. Please try again later.</Text>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" w="full" mb="12">
      {!isOnboarded && !postkyc.isPending && !user?.isRefetching && (
        <>
          <Text color={uiColors.sonicSilver()} fontSize="sm" mb="4">
            {fiInfo.data?.enabledFeatureSet?.isSecuritiesEnabled
              ? "You're almost there! We're required by law to collect certain information that helps us confirm your eligibility and identity when logging in. This helps keep your account safe and secure."
              : 'To comply with Know Your Customer (KYC) laws, we need to verify your identity. Please enter your information below'}
          </Text>

          <CfForm id="kycForm" onSubmit={handleSubmit(onSubmit)} mt="6">
            <Flex flexDirection="column" gap="10">
              {groupedFields &&
                Object.entries(groupedFields).map(([key, group]) => {
                  if (size(group.fields) > 0) {
                    return (
                      <KycSection
                        key={key}
                        fields={group.fields}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        heading={group.heading}
                      />
                    );
                  }
                })}
            </Flex>

            {size(kycFields.data?.need) === 0 && (
              <Text color={uiColors.sonicSilver()} fontSize="md" mb="4" textAlign="center">
                Looks like we already have all the information we need from you. Please click the button below to
                continue the onboarding process.
              </Text>
            )}

            <Button type="submit" size="lg" isLoading={postkyc.isPending || user?.isRefetching} mt={6}>
              Submit and continue
            </Button>

            {postkyc.error && (
              <Text color={uiColors.heroicRed()} textAlign="center">
                {getErrorDetail(postkyc.error)}
              </Text>
            )}
          </CfForm>
        </>
      )}

      {(user?.isRefetching || postkyc.isPending || postkyc.isSuccess || kycFields.isLoading) && (
        <KycLoading showLoadingMessages={postkyc.isPending || postkyc.isSuccess || user.isRefetching} />
      )}
    </Flex>
  );
};

export default KycInfoView;
