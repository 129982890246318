'use client';
import { Box, Flex, Heading, IconButton, ModalProps, SlideFade } from '@chakra-ui/react';
import { CfModal, IconCaretLeft } from '@cryptofi/core-ui';
import { useCallback, useEffect, useState } from 'react';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { OnboardingModalViews } from '~/customTypes';
import { useFeatureSetEnabled, useGetKycFields, useOnboardingInfo, usePostTelemetryEvent } from '~/hooks';

import KycInfoView from './KycInfoView';
import KycResultsView from './KycResultsView';
import ProgressBar from './ProgressBar';
import SelectProductsView from './SelectProductsView';
import TermsAndConditionsView from './TermsAndConditionsView';
import WelcomeCryptoView from './WelcomeCryptoView';
import WelcomeSecuritiesView from './WelcomeSecuritiesView';

interface Props extends Omit<ModalProps, 'children'> {
  hasNewTerms?: boolean;
}

const OnboardingModal = ({ onClose, isOpen, hasNewTerms, ...rest }: Props) => {
  const {
    currentOnboardingStatus,
    isOnboarded,
    isOnboardedSecurities,
    isOnboardedCrypto,
    isRejectedOrPending,
    selectedProducts,
    setSelectedProducts,
    isRejectedOrPendingCrypto,
    isRejectedOrPendingSecurities,
  } = useOnboardingInfo();
  const { isEnabled } = useFeatureSetEnabled();

  const [modalView, setModalView] = useState<OnboardingModalViews>('welcomeSecurities');

  const kycFields = useGetKycFields({ selectedProducts });
  const { trackEvent } = usePostTelemetryEvent();
  const trackOnClose = useCallback(() => {
    const kycForm = document.getElementById('kycForm') as HTMLFormElement;
    if (kycForm) {
      const kycFields = Array.prototype.slice.call(kycForm.elements) as HTMLInputElement[];
      const capturedFields = Array.from(kycFields)
        .map((field) => {
          return field.value ? field.name : null;
        })
        .filter((fieldName) => fieldName !== null);

      trackEvent(TelemetryClientSideEventsEnum.KYCModalClosedKYCFormClient, { capturedFields });
    }

    onClose();
  }, [onClose, trackEvent]);

  // setModalView useEffect
  useEffect(() => {
    if (isOpen) {
      const handleSetModalView = (newModalView: OnboardingModalViews) => {
        // prevents accidental refreshing of modal views
        if (modalView !== newModalView) {
          setModalView(newModalView);
        }
      };
      if (hasNewTerms) {
        handleSetModalView('termsAndConditions');
      } else if (['approved', 'pendingVerification', 'rejected'].includes(currentOnboardingStatus || '')) {
        handleSetModalView('kycResults');
      } else if (modalView !== 'termsAndConditions' && currentOnboardingStatus === 'notAcceptedTandC') {
        if (isEnabled(['securities', 'crypto'])) {
          if (!isRejectedOrPending && !isOnboarded) {
            handleSetModalView('selectProducts');
          } else if (!isOnboardedSecurities && !isRejectedOrPendingSecurities) {
            handleSetModalView('welcomeSecurities');
          } else if (!isOnboardedCrypto && !isRejectedOrPendingCrypto) {
            handleSetModalView('welcomeCrypto');
          }
        } else if (isEnabled(['securities'])) {
          handleSetModalView('welcomeSecurities');
        } else if (isEnabled(['crypto'])) {
          handleSetModalView('welcomeCrypto');
        }
      } else if (currentOnboardingStatus === 'notStartedKyc') {
        handleSetModalView('kycInfo');
      }
    }
  }, [
    isOpen,
    setModalView,
    hasNewTerms,
    selectedProducts,
    isOnboarded,
    isEnabled,
    isOnboardedSecurities,
    isOnboardedCrypto,
    modalView,
    currentOnboardingStatus,
    isRejectedOrPending,
    isRejectedOrPendingSecurities,
    isRejectedOrPendingCrypto,
  ]);

  const views = {
    welcomeSecurities: {
      showView: modalView === 'welcomeSecurities',
      bodyContent: <WelcomeSecuritiesView setModalView={setModalView} />,
      hasProgressBar: false,
      headerText: undefined,
    },
    welcomeCrypto: {
      showView: modalView === 'welcomeCrypto',
      bodyContent: <WelcomeCryptoView setModalView={setModalView} />,
      hasProgressBar: false,
      headerText: undefined,
    },
    selectProducts: {
      showView: modalView === 'selectProducts',
      bodyContent: (
        <SelectProductsView
          setSelectedProducts={setSelectedProducts}
          setModalView={setModalView}
          selectedProducts={selectedProducts}
        />
      ),
      hasProgressBar: false,
      headerText: undefined,
    },
    termsAndConditions: {
      showView: modalView === 'termsAndConditions',
      bodyContent: (
        <TermsAndConditionsView hasNewTerms={hasNewTerms} selectedProducts={selectedProducts} onClose={onClose} />
      ),
      hasProgressBar: !hasNewTerms,
      headerText: hasNewTerms ? 'Updated Terms and Conditions' : 'Create account',
    },
    kycInfo: {
      showView: modalView === 'kycInfo' && kycFields.isSuccess,
      bodyContent: (
        <KycInfoView
          kycFields={kycFields}
          selectedProducts={selectedProducts}
          isOnboarded={currentOnboardingStatus === 'approved'}
        />
      ),
      hasProgressBar: true,
      headerText: 'Create account',
    },
    kycResults: {
      showView: modalView === 'kycResults',
      bodyContent: <KycResultsView isOnboarded={currentOnboardingStatus === 'approved'} onClose={onClose} />,
      hasProgressBar: false,
      headerText: ['rejected', 'pendingVerification'].includes(currentOnboardingStatus || '')
        ? 'Application pending review'
        : '',
    },
  };

  const showBackButton =
    modalView === 'termsAndConditions' && isEnabled(['securities', 'crypto']) && isOnboarded === false && !hasNewTerms;

  return (
    <CfModal
      headerContent={
        <Heading as="h1" size="md" textAlign="center">
          {showBackButton && (
            <IconButton
              icon={<IconCaretLeft __css={{ path: { fill: 'black !important' } }} />}
              position="absolute"
              left="3"
              top="2"
              variant="ghost"
              aria-label="Back to product selection"
              size="sm"
              onClick={() => {
                setModalView('selectProducts');
              }}
              _hover={{ bg: 'blackAlpha.100' }}
              _focus={{ bg: 'blackAlpha.100' }}
            />
          )}

          {views[modalView].headerText}
        </Heading>
      }
      onClose={trackOnClose}
      closeOnOverlayClick={false}
      closeOnEsc={!hasNewTerms}
      isDisabledCloseButton={hasNewTerms}
      isOpen={isOpen}
      size={modalView === 'kycResults' ? 'lg' : '4xl'}
      {...rest}
    >
      <Flex direction="column" px={{ md: '10' }}>
        <SlideFade in={views[modalView].hasProgressBar}>
          {views[modalView].hasProgressBar && (
            <Box mb="4">
              <ProgressBar />
            </Box>
          )}
        </SlideFade>

        {Object.entries(views).map(([key, { showView, bodyContent }]) => (
          <SlideFade in={showView} key={key}>
            {showView && bodyContent}
          </SlideFade>
        ))}
      </Flex>
    </CfModal>
  );
};

export default OnboardingModal;
