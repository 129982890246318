import { Flex, Text } from '@chakra-ui/react';
import { CfForm, CfInput, CfSelect, CfSpinner, formatUsd, uiColors } from '@cryptofi/core-ui';
import { debounce, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AllAssetIds } from '~/customTypes';
import { isCrypto } from '~/utils';

import { useModalContext } from './ModalContext';
import PresetAmounts from './PresetAmounts';
import QuantityConversion from './QuantityConversion';
import SelectAssetButton from './SelectAssetButton';
import ToggleCurrencyButton from './ToggleCurrencyButton';
import { TransactionFormValues } from './transactionSchema';

const SellForm = ({ isLoading }: { isLoading: boolean }) => {
  const { register, setValue, trigger, control, formState, getValues, resetField } =
    useFormContext<TransactionFormValues>();
  const {
    setModalView,
    sellCurrency,
    setSellCurrency,
    bankAccounts,
    selectedAssetId,
    amountAvailableAsset,
    amountAvailableUsd,
    sellPrice,
  } = useModalContext();

  // reset account selection when the asset changes, to prevent a disabled account from being pre-selected
  useEffect(() => {
    resetField('sellAccountId');
  }, [selectedAssetId, resetField]);

  // select the change handler based on transaction currency
  // currency mode uses onValueChange instead of onChange
  // https://www.npmjs.com/package/react-currency-input-field#onvaluechange
  const changeHandler = () => {
    // prevent validation from being too aggressive on checking min amount
    const validateDebounced = debounce(() => {
      // skip validation if the field is empty or it will fail on non numeric input
      if (getValues('sellCurrencyAmount')) {
        trigger('sellCurrencyAmount');
      }
    }, 1000);

    if (sellCurrency === 'USD') {
      return {
        onValueChange: (value: string | undefined) => {
          // make it possible to clear the input
          if (value === undefined) {
            resetField('sellCurrencyAmount', { defaultValue: '' });
          }

          if (value) {
            // set the value and trigger validation
            setValue('sellCurrencyAmount', value);
            validateDebounced();
          }
        },
      };
    }

    return {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
          setValue('sellCurrencyAmount', e.target.value);
          validateDebounced();

          if (formState.touchedFields.sellAccountId) {
            trigger('sellAccountId');
          }
        } else {
          resetField('sellCurrencyAmount', { defaultValue: '' });
        }
      },
    };
  };

  // price data is (re)fetched when the modal opens, so available amounts need updated manually
  useEffect(() => {
    setValue('sellAmountAvailableAsset', amountAvailableAsset);
    setValue('sellAmountAvailableUsd', amountAvailableUsd);
  }, [amountAvailableUsd, amountAvailableAsset, setValue]);

  return (
    <Flex flexDir="column" gap="6" w="full">
      {isLoading && <CfSpinner />}

      {!isLoading && (
        <CfForm
          gap="0"
          id="sellForm"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // prevent enter key from navigating back to search
            }
          }}
          onSubmit={async () => {
            // validate currency amount again to handle edge cases due to debounce
            await trigger('sellCurrencyAmount');

            if (isEmpty(formState.errors)) {
              setModalView('reviewTransaction');
            }
          }}
        >
          {/* hidden inputs are used for validation, and get their values via setValue */}
          <CfInput name="sellIsUsd" register={register} defaultValue="true" type="hidden" />

          <CfInput name="sellAmountAvailableAsset" register={register} type="hidden" />

          <CfInput name="sellAmountAvailableUsd" register={register} type="hidden" />

          <Flex gap="6" flexDirection="column" position="relative">
            {Boolean(getValues('sellCurrencyAmount')) && <QuantityConversion />}

            {/* this input is controlled in order to set its value programmatically in currency mode */}
            <Controller
              control={control}
              name="sellCurrencyAmount"
              render={({ field: { value } }) => {
                return (
                  <CfInput
                    name=""
                    value={value as string}
                    {...changeHandler()}
                    errorMessage={formState.errors.sellCurrencyAmount?.message}
                    type={sellCurrency === 'USD' ? 'currency' : 'number'}
                    isRequired
                    label="Amount"
                    paddingRight="6em"
                    leftAddon={<SelectAssetButton selectedAssetId={selectedAssetId} setModalView={setModalView} />}
                    rightElement={
                      isCrypto(selectedAssetId) ? (
                        <ToggleCurrencyButton
                          currency={sellCurrency}
                          setCurrency={setSellCurrency}
                          selectedAsset={selectedAssetId as AllAssetIds}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                );
              }}
            />

            <Flex mt="-4" fontSize="xs" justifyContent="space-between" color={uiColors.sonicSilver()}>
              <Text>Current price: {formatUsd({ amount: sellPrice })}</Text>

              {selectedAssetId && (
                <Text ml="auto">
                  Available balance: {/* eslint-disable-next-line react/jsx-newline */}
                  {sellCurrency === 'USD' ? formatUsd({ amount: amountAvailableUsd }) : amountAvailableAsset}
                </Text>
              )}
            </Flex>

            <PresetAmounts
              maxAmount={amountAvailableUsd}
              transactionType="sell"
              onClick={(e) => {
                const target = e.target as HTMLElement;

                // flip back to USD if necessary
                if (sellCurrency !== 'USD' && target.tagName === 'BUTTON') {
                  setSellCurrency('USD');
                  setValue('sellIsUsd', true);
                }

                if (target.tagName === 'BUTTON') {
                  const button = target as HTMLButtonElement;

                  // set the sell amount and validate to update isValid
                  setValue('sellCurrencyAmount', button.value, { shouldValidate: true });
                }
              }}
            />

            <CfSelect
              name="sellAccountId"
              label="Receive in"
              isRequired
              register={register}
              errorMessage={formState.errors.sellAccountId?.message}
              onChange={(e) => {
                const target = e.target as HTMLSelectElement;
                setValue('sellAccountId', target.value, { shouldValidate: true });
              }}
              defaultValue=""
            >
              <option disabled value="">
                Select...
              </option>

              {bankAccounts.map(
                ({ accountId, displayAccountNumber, accountDescription, availableBalance, accountType }) => {
                  // only allow checking accounts for securities transactions
                  const isDisabled = !isCrypto(selectedAssetId) && accountType.toLowerCase() !== 'checking';

                  return (
                    <option key={accountId} value={accountId} disabled={isDisabled}>
                      {/* eslint-disable-next-line react/jsx-newline */}
                      {accountDescription} **{displayAccountNumber} - {formatUsd({ amount: availableBalance })}
                    </option>
                  );
                },
              )}
            </CfSelect>
          </Flex>
        </CfForm>
      )}
    </Flex>
  );
};

export default SellForm;
