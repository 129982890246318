import DisclosuresModal from './DisclosuresModal';

// TODO add investment modal, onboarding modal

const GlobalModals = () => {
  return (
    <>
      <DisclosuresModal />
    </>
  );
};

export default GlobalModals;
