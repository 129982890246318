'use client';

import { Button, Flex, Heading } from '@chakra-ui/react';
import { CfForm, CfInput, CfModal, CfSelect, CfTag, dateToTimestamp, IconClose, uiColors } from '@cryptofi/core-ui';
import { decamelize } from 'humps';
import { get, keys, size } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { TxHistoryFormData } from '~/customTypes';
import { useGetUser, usePostTelemetryEvent } from '~/hooks';
import { ActiveFilter } from '~/transaction-history/page';

interface TransactionHistoryFilterModal {
  clearFilter: (config: { key?: string | undefined; all?: boolean | undefined }) => void;
  isOpen: boolean;
  onClose: () => void;
  setParams: Dispatch<SetStateAction<TxHistoryFormData>>;
  txHistoryForm: UseFormReturn<TxHistoryFormData>;
  activeFilters: ActiveFilter[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const TransactionHistoryFilterModal = ({
  clearFilter,
  isOpen,
  onClose,
  setParams,
  txHistoryForm,
  activeFilters,
  setCurrentPage,
}: TransactionHistoryFilterModal) => {
  const { trackEvent } = usePostTelemetryEvent();

  const {
    watch,
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, isDirty },
  } = txHistoryForm;

  const user = useGetUser();
  const assets = [...keys(get(user, 'data.balance.securities', {})), ...keys(get(user, 'data.balance.tokens', {}))].map(
    (asset) => ({
      name: asset,
      value: asset,
    }),
  );

  const txHistoryFormData = watch();

  const onSubmit = () => {
    if (!isValid) {
      return;
    }
    interface FormParams {
      [key: string]: string | undefined;
    }
    const formParams: FormParams = {
      asset: txHistoryFormData.asset,
      transactionName: txHistoryFormData.transactionName,
      transactTimeStart: '',
      transactTimeEnd: '',
    };
    if (txHistoryFormData.transactTimeStart) {
      const utcTimeStart = dateToTimestamp({
        timeString: txHistoryFormData.transactTimeStart,
      });
      formParams.transactTimeStart = utcTimeStart;
    }
    if (txHistoryFormData.transactTimeEnd) {
      const utcTimeEnd = dateToTimestamp({
        timeString: txHistoryFormData.transactTimeEnd,
        toEndDate: true,
      });
      formParams.transactTimeEnd = utcTimeEnd;
    }
    setParams(formParams);
    reset({}, { keepValues: true }); //to reset isDirty
    setCurrentPage(0);

    // Track ClickedTXHistoryAppliedFiltersClient telemetry event with the current form parameters
    // Note: There is a bug that does not allow any param outside of `transactionName` to be sent to Mixpanel;
    // As such, we are filtering out any key in formParams that is not `transactionName`
    const filteredFormParams: Partial<FormParams> = {};
    if ('transactionName' in formParams) {
      filteredFormParams.transactionName = formParams.transactionName;
    }
    trackEvent(TelemetryClientSideEventsEnum.ClickedTXHistoryAppliedFiltersClient, filteredFormParams);

    onClose();
  };

  const formId = 'filterForm'; // links the button and form
  const hasActiveFilters = Boolean(size(activeFilters));

  return (
    <>
      <CfModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        headerContent={
          <Flex justifyContent="center" pos="relative" alignItems="center">
            {hasActiveFilters && (
              <Button
                variant="ghost"
                onClick={() => {
                  clearFilter({ all: true });
                  reset({}, { keepValues: true }); //to reset isDirty
                }}
                pos="absolute"
                top={-1}
                left={-3}
              >
                Clear all
              </Button>
            )}

            <Heading as="h3" size="md">
              Filters
            </Heading>
          </Flex>
        }
        footerContent={
          <Button form={formId} w="full" isDisabled={!isDirty} type="submit">
            Apply filters
          </Button>
        }
      >
        <CfForm onSubmit={handleSubmit(onSubmit)} id={formId} gap="1" w="full">
          {hasActiveFilters && (
            <>
              <Heading as="h4" size="sm" mt="4">
                Active filters
              </Heading>

              <Flex my="4" gap="4" wrap="wrap">
                {activeFilters.map(([key, value]) => (
                  <CfTag
                    key={key}
                    size="sm"
                    py="2"
                    px="4"
                    label={`${decamelize(key as string, { separator: ' ' })}: ${value}`}
                    rightIcon={IconClose}
                    onClick={() => {
                      clearFilter({ key: key as string });
                      reset({}, { keepValues: true }); //to reset isDirty
                    }}
                    _hover={{
                      path: {
                        fill: uiColors.heroicRed(1),
                      },
                    }}
                    textTransform="capitalize"
                  />
                ))}
              </Flex>
            </>
          )}

          <Heading as="h4" size="sm">
            Filters
          </Heading>

          <Flex gap="3" py="3" flexDirection={{ base: 'column', md: 'row' }}>
            <CfSelect
              label="Transaction Type"
              name="transactionName"
              register={register}
              errorMessage={errors.transactionName?.message as string}
            >
              <option value="">All</option>

              <option value="Buy">Buy</option>

              <option value="Sell">Sell</option>
            </CfSelect>

            <CfSelect label="Asset" name="asset" register={register} errorMessage={errors.token?.message}>
              <option value="">All</option>

              {assets
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((asset) => (
                  <option key={String(asset.value)} value={asset.value}>
                    {asset.name}
                  </option>
                ))}
            </CfSelect>
          </Flex>

          <Flex gap="3" py="3" flexDirection={{ base: 'column', md: 'row' }}>
            <CfInput
              label="Start Date"
              type="date"
              name="transactTimeStart"
              register={register}
              errorMessage={errors.transactTimeStart?.message}
            />

            <CfInput
              label="End Date"
              type="date"
              name="transactTimeEnd"
              register={register}
              errorMessage={errors.transactTimeEnd?.message}
            />
          </Flex>
        </CfForm>
      </CfModal>
    </>
  );
};

export default TransactionHistoryFilterModal;
