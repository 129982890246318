import { Button, Flex } from '@chakra-ui/react';
import { CfButtonGroup, CfButtonGroupButton } from '@cryptofi/core-ui';
import { useFormContext } from 'react-hook-form';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { AssetUnavailable } from '~/components';
import { TransactionTypes } from '~/customTypes';
import {
  useCanUserPurchaseSecurity,
  useGetSystemStatus,
  useGetUser,
  usePostTelemetryEvent,
  useSystemDownByFeature,
} from '~/hooks';
import { isCrypto, isFalse } from '~/utils';

import BuyForm from './BuyForm';
import { useModalContext } from './ModalContext';
import SellForm from './SellForm';
import { isFormValid } from './utils';

const StartTransaction = ({ isLoading }: { isLoading: boolean }) => {
  const { transactionType } = useModalContext();

  return (
    <Flex py="4" width="100%">
      {transactionType === 'buy' && <BuyForm isLoading={isLoading} />}

      {transactionType !== 'buy' && <SellForm isLoading={isLoading} />}
    </Flex>
  );
};
export default StartTransaction;

// external sub components

const HeaderContent = () => {
  const { transactionType, setTransactionType, selectedAssetId, selectedSecurity } = useModalContext();
  const { clearErrors, setValue } = useFormContext();
  const { trackEvent } = usePostTelemetryEvent();
  const systemStatus = useGetSystemStatus();

  const user = useGetUser();
  const canUserPurchaseSecurity = useCanUserPurchaseSecurity(selectedSecurity);

  const handleOnClick = (option: TransactionTypes) => {
    setTransactionType(option);
    setValue('transactionType', option);
    // clear any errors when switching forms so that the preview transaction button does not get blocked by an error from the non selected form
    clearErrors();

    if (option === 'buy') {
      trackEvent(TelemetryClientSideEventsEnum.ClickedAssetBuyButtonClient, { asset: selectedAssetId });
    } else if (option === 'sell') {
      trackEvent(TelemetryClientSideEventsEnum.ClickedAssetSellButtonClient, { asset: selectedAssetId });
    }
  };

  const buttonProps = (option: TransactionTypes) => {
    return {
      onClick: () => handleOnClick(option),
      isSelected: transactionType === option,
      minWidth: '16',
      paddingX: '4',
    };
  };

  const assetUnavailable = () => {
    const westreet = '23177';
    const isUnavailableDisabled = user.data?.fiId === westreet && selectedAssetId === 'XLM'; // TODO remove when sFox supports XLM
    const isUnavailableRiskTolerance = !canUserPurchaseSecurity && !isCrypto(selectedAssetId);
    const restProps = {
      mt: '10',
    };

    // order must stay in sync with logic in securities asset detail page to avoid conflicting messages
    if (isUnavailableDisabled) {
      return <AssetUnavailable reason="disabled" showWeStreetContact {...restProps} />;
    }

    if (!isCrypto(selectedAssetId) && isFalse(systemStatus.data?.isSecuritiesAvailable)) {
      return <AssetUnavailable reason="systemDownSecurities" {...restProps} />;
    }

    if (isCrypto(selectedAssetId) && isFalse(systemStatus.data?.isCryptoAvailable)) {
      return <AssetUnavailable reason="systemDownCrypto" {...restProps} />;
    }

    if (!isCrypto(selectedAssetId) && isFalse(systemStatus.data?.securitiesTradingOpen)) {
      return <AssetUnavailable reason="marketClosed" {...restProps} />;
    }

    if (isUnavailableRiskTolerance) {
      return <AssetUnavailable reason="riskTolerance" {...restProps} />;
    }
  };

  return (
    <>
      <Flex justifyContent="center" mt="6">
        <CfButtonGroup>
          <CfButtonGroupButton {...buttonProps('buy')}>Buy</CfButtonGroupButton>

          <CfButtonGroupButton {...buttonProps('sell')}>Sell</CfButtonGroupButton>
        </CfButtonGroup>
      </Flex>

      {assetUnavailable()}
    </>
  );
};
StartTransaction.HeaderContent = HeaderContent;

const FooterContent = () => {
  const { getFieldState, getValues } = useFormContext();
  const { transactionType, selectedAssetId, selectedSecurity } = useModalContext();
  const { trackEvent } = usePostTelemetryEvent();
  const user = useGetUser();
  const { isSystemDownByFeature } = useSystemDownByFeature();
  const systemStatus = useGetSystemStatus();

  const handlePreviewTransactionClick = () => {
    const eventType =
      transactionType === 'buy'
        ? TelemetryClientSideEventsEnum.ClickedAssetPreviewBuyButtonClient
        : TelemetryClientSideEventsEnum.ClickedAssetPreviewSellButtonClient;

    trackEvent(eventType, { asset: selectedAssetId });
  };

  const canUserPurchaseSecurity = useCanUserPurchaseSecurity(selectedSecurity);

  const westreet = '23177';
  const isUnavailableDisabled = user.data?.fiId === westreet && selectedAssetId === 'XLM'; // TODO remove when sFox supports XLM
  // Disable the preview button if it is a buy transaction for a security and the user's risk profile is too low
  const isUnavailableRiskTolerance =
    !canUserPurchaseSecurity && transactionType === 'buy' && !isCrypto(selectedAssetId);

  const isUnavailableMarketClosed = !isCrypto(selectedAssetId) && isFalse(systemStatus.data?.securitiesTradingOpen);
  return (
    <Flex direction="column" w="full">
      <Button
        // each form gets a unique id to keep both onsubmit events from firing
        form={transactionType === 'buy' ? 'buyForm' : 'sellForm'}
        isDisabled={
          !isFormValid({ getValues, getFieldState, transactionType }) ||
          isSystemDownByFeature() ||
          isUnavailableDisabled ||
          isUnavailableRiskTolerance ||
          isUnavailableMarketClosed
        }
        type="submit"
        w="full"
        variant="outline"
        onClick={handlePreviewTransactionClick}
      >
        Preview order
      </Button>
    </Flex>
  );
};
StartTransaction.FooterContent = FooterContent;
