'use client';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  ResponsiveValue,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { dateFormats, formatUsd, IconCaretDown, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { Camelized } from 'humps';

import { Transaction } from '~/codegen/types';
import { AssetLogo, LabelTd, TransactionStatus, ValueTd } from '~/components';

type TransactionAccordionProps = {
  transaction: Camelized<Transaction>;
  initiallyCollapsed?: boolean;
  variant?: ResponsiveValue<string> | undefined;
  allowToggle?: boolean;
};

const TransactionAccordion = ({
  transaction,
  initiallyCollapsed = false,
  variant,
  allowToggle = true,
}: TransactionAccordionProps) => {
  return (
    <Accordion
      w="full"
      defaultIndex={initiallyCollapsed ? undefined : [0]}
      allowToggle={allowToggle}
      variant={variant || ''}
    >
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              cursor={allowToggle ? 'pointer' : 'auto'}
              _hover={allowToggle ? { bg: uiColors.lighthouse() } : { bg: uiColors.white() }}
            >
              <Flex w="full">
                <AssetLogo textAlign="left" assetId={transaction?.asset || ''} showId flexGrow="1" />

                <Flex direction="column">
                  <Text fontFamily="heading" textAlign="right">
                    {transaction?.assetAmount || 0}
                  </Text>

                  <Text textAlign="right">
                    {dayjs.utc(transaction?.transactTime).local().format(dateFormats.dateOnly)}
                  </Text>
                </Flex>
              </Flex>

              {allowToggle && (
                <IconCaretDown
                  ml="4"
                  transform={isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
                  transition="transform 0.2s ease-in-out"
                  h="1rem"
                  w="1rem"
                  __css={{ path: { fill: uiColors.sonicSilver() } }}
                />
              )}
            </AccordionButton>

            <AccordionPanel background={uiColors.lighthouse()}>
              <Table variant="unstyled">
                <Tbody>
                  <Tr fontFamily="heading" fontSize="md">
                    <Td pl="0" py="2.5">
                      Status
                    </Td>

                    <Td pr="0" py="2.5" textAlign="right">
                      <TransactionStatus transaction={transaction} justifyContent="flex-end" />
                    </Td>
                  </Tr>

                  <Tr>
                    <LabelTd>Type</LabelTd>

                    <ValueTd>{transaction.transactionName}</ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Asset price</LabelTd>

                    <ValueTd>{formatUsd({ amount: transaction.assetPrice || 0 })}</ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Amount</LabelTd>

                    <ValueTd>{formatUsd({ amount: transaction.usdAmountGross || 0 })}</ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Transaction fee</LabelTd>

                    <ValueTd>{formatUsd({ amount: transaction.feeAmount || 0 })}</ValueTd>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TransactionAccordion;
