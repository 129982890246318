/* eslint-disable react/jsx-newline */
'use client';

// eslint-disable-next-line no-restricted-imports
import { Alert, AlertProps, Box, Heading, Link, Text } from '@chakra-ui/react';
import { formatPhoneNumber, IconInfo, uiColors } from '@cryptofi/core-ui';

interface Props extends AlertProps {
  reason: 'disabled' | 'riskTolerance' | 'marketClosed' | 'systemDownCrypto' | 'systemDownSecurities';
  showWeStreetContact?: boolean;
}

const AssetUnavailable = ({ reason, showWeStreetContact, ...rest }: Props) => {
  const headingLookup = {
    disabled: 'Unable to invest in this asset',
    riskTolerance: 'Unable to invest in this asset',
    marketClosed: 'Securities market is closed',
    systemDownCrypto: 'Crypto trading is unavailable',
    systemDownSecurities: 'Securities trading is unavailable',
  };

  const textLookup = {
    disabled: `This asset is currently unavailable to trade. If you currently have holdings of this asset don’t worry, they are safe and secure. Trading will be available again soon!`,

    riskTolerance: `Based on your selected risk tolerance, this asset is not available for trading. Your investment options are tailored to match your risk profile for a more secure investing experience.`,

    marketClosed: `Please come back later or select another asset type.`,

    systemDownCrypto: `Crypto trading is temporarily unavailable. Please try again later.`,

    systemDownSecurities: `Securities trading is temporarily unavailable. Please try again later.`,
  };

  return (
    <Alert borderColor={uiColors.coolElegance()} colorScheme="whiteAlpha" {...rest}>
      <Box>
        <IconInfo
          mr="6"
          __css={{
            path: { fill: uiColors.coolElegance() },
          }}
        />
      </Box>

      <Box color={uiColors.black()} fontSize="sm">
        <Heading as="h3" color={uiColors.black()} fontWeight="1200" fontSize="md" mb="1">
          {headingLookup[reason]}
        </Heading>

        <Text display="inline" _after={{ content: '" "' }}>
          {textLookup[reason]}
        </Text>

        {/* TODO remove temp content for WeStreet */}
        {showWeStreetContact && (
          <Text display="inline">
            If you would like to liquidate your position in the meantime, please contact the InvestiFi support desk at{' '}
            <Link whiteSpace="nowrap" fontFamily="heading" href="tel:8777984609">
              {formatPhoneNumber('8777984609')}
            </Link>
            .
          </Text>
        )}
      </Box>
    </Alert>
  );
};

export default AssetUnavailable;
