'use client';
import { Button, CardHeader, Flex, FlexProps, Heading, Text, VisuallyHidden } from '@chakra-ui/react';
import { CfCard, formatPercentage, formatUsd, getDelta, uiColors } from '@cryptofi/core-ui';

import { InvestmentStat } from '~/components';

interface Props extends FlexProps {
  assetId: string;
  amountOwned?: number;
  amountUsd?: number;
  avgCost?: number;
  gainLossPercentage?: number;
  gainLossDollarAmount?: number;
  sparkline?: any[];
  onOpenModal: () => void;
}

const InvestmentAsset = ({
  assetId,
  amountOwned = 0,
  amountUsd = 0,
  avgCost = 0,
  gainLossPercentage = 0,
  gainLossDollarAmount = 0,
  sparkline = [],
  onOpenModal,
  ...rest
}: Props) => {
  const { isNetGain, percentChange } = getDelta({
    data: sparkline,
    dataKey: 'value',
  });
  // is gain for all of time, while isNetGain is for the current day
  const isTotalGain = gainLossDollarAmount >= 0;

  return (
    <CfCard gap="2" p="0" overflow="hidden" {...rest}>
      <CardHeader>
        <Flex justify="space-between">
          <Heading as="h1" size="md">
            My investment
          </Heading>

          <Button display={{ base: 'none', md: 'flex' }} onClick={onOpenModal}>
            Trade {assetId}
          </Button>
        </Flex>
      </CardHeader>

      <Flex alignItems="flex-end" justifyContent="space-between" direction="row" px="4">
        <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '2', md: '6' }} flexGrow="1">
          <InvestmentStat label="Current value" size="lg" value={formatUsd({ amount: amountUsd })} />

          <InvestmentStat
            label="Return"
            isIncrease={isTotalGain}
            showArrow
            size="lg"
            value={formatPercentage({ number: gainLossPercentage })}
          />
        </Flex>

        <Text
          display={{ base: 'none', md: 'block' }}
          fontFamily="heading"
          color={isNetGain ? uiColors.mint() : uiColors.heroicRed()}
        >
          <VisuallyHidden>Investment return percentage:</VisuallyHidden>

          {`${formatPercentage({
            number: percentChange,
            isDecimal: false,
          })} today`}
        </Text>
      </Flex>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        p="4"
        bg={uiColors.lighthouse()}
        borderTop="solid 1px"
        borderColor={uiColors.coolElegance()}
        gap={{ base: '2', md: '6' }}
      >
        <InvestmentStat label={`Amount (${assetId})`} value={amountOwned} />

        <InvestmentStat label="Average purchase price" value={formatUsd({ amount: avgCost })} />

        <InvestmentStat
          isIncrease={isTotalGain}
          label={`Total ${isTotalGain ? 'gains' : 'losses'}`}
          showArrow
          value={formatUsd({ amount: gainLossDollarAmount })}
        />
      </Flex>
    </CfCard>
  );
};

export default InvestmentAsset;
