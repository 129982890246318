import { Box, Text, Tooltip } from '@chakra-ui/react';

const SystemStatus = ({ enableRefetch, status }: { enableRefetch: boolean; status?: boolean }) => {
  return (
    <Tooltip label={enableRefetch ? null : 'Refetch disabled'} hasArrow>
      <Box width="fit-content" as="span">
        <Text as="span" color={enableRefetch ? 'inherit' : 'gray.500'} fontFamily="body">
          {status ? '✔' : '✘'}
        </Text>
      </Box>
    </Tooltip>
  );
};

export default SystemStatus;
