'use client';

import { Box, CardFooter, FlexProps } from '@chakra-ui/react';
import { CfAreaChart, CfCard, formatUsd, uiColors } from '@cryptofi/core-ui';
import { Dispatch, SetStateAction } from 'react';

import { AllAssetIds, ChartTimeRange } from '~/customTypes';
import chartDateFormatter from '~/utils/chartDateFormatter';

interface Props extends FlexProps {
  selectedTimeRange: ChartTimeRange;
  setSelectedTimeRange: Dispatch<SetStateAction<ChartTimeRange>>;
  chartData: any[];
  totalValue: number;
  isLoaded: boolean;
  valueType: 'portfolio' | 'asset';
  assetId?: AllAssetIds;
}

const PerformanceOverTime = ({
  selectedTimeRange,
  // setSelectedTimeRange,
  chartData,
  // totalValue,
  // isLoaded,
  // valueType,
  // assetId,
  ...rest
}: Props) => {
  const chartHeight = { base: '12rem', lg: '16rem' };

  return (
    <CfCard p="0" gap="0" {...rest}>
      <Box height={chartHeight}>
        <CfAreaChart
          data={chartData}
          dataKey="value"
          height="inherit"
          showTooltip // TODO in core UI
          formatter={(amount) => String(formatUsd({ amount }))}
          labelFormatter={(_, chartProps) => {
            if (chartProps.length) {
              return chartDateFormatter({ date: chartProps[0].payload.date, selectedTimeRange });
            }
          }}
        />

        {/* <TotalValue
          hideBalance={hideBalance}
          ref={innerCardRef}
          formattedBalance={formattedBalance}
          totalValue={totalValue}
          isNetGain={isNetGain}
          delta={delta}
          isLoaded={isLoaded}
          valueType={valueType}
          assetId={assetId}
        /> */}

        {/* <Flex
          justifyContent="flex-end"
          position="absolute"
          bottom="4"
          right={{ base: 0, lg: 4 }}
          left={{ base: 0, lg: 'auto' }}
          margin="auto"
          width="fit-content"
        >
          <TimeRangeSelector selectedTimeRange={selectedTimeRange} setSelectedTimeRange={setSelectedTimeRange} />
        </Flex> */}
      </Box>

      <CardFooter
        borderTop="solid 1px"
        borderColor={uiColors.coolElegance()}
        gap={{ base: 4, lg: 8 }}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
      >
        {/* <TrendIndicator
          heading="Investments"
          isNetGain // TODO
          amount={investments}
          isLoading={isLoading}
          flexGrow={{ base: 1, lg: 0 }}
          justifyContent="center"
        />

        <TrendIndicator
          heading="Gains"
          isNetGain // TODO
          amount={gains}
          isLoading={isLoading}
          flexGrow={{ base: 1, lg: 0 }}
          justifyContent="center"
        /> */}
      </CardFooter>
    </CfCard>
  );
};

export default PerformanceOverTime;
