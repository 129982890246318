import isDev from '~/utils/isDev';

import Existing from './Existing';
import New from './New';

const PerformanceOverTime = (props: any) => {
  const { isLocalDev, isVercelDev } = isDev();

  if (isLocalDev || isVercelDev) {
    return <New {...props} />;
  }

  return <Existing {...props} />;
};

export default PerformanceOverTime;
